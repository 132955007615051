import { useEffect } from 'react';

export const useRTETooltipPositionCorrector = (isMobile = false) => {
  const reposition = () => {
    // max-width for mobile is set to 50vw and for desktop is 350px
    const innerWidth = window.innerWidth;
    const maxWidth = isMobile ? innerWidth / 2 : 350;
    const maxWidthHalf = maxWidth / 2;

    const tooltips = Array.from(document.getElementsByClassName('tt'));
    tooltips.forEach((t) => {
      const tooltipRect = t.getBoundingClientRect();

      const tooltip = t.getElementsByClassName('tt__content')[0] as HTMLElement;
      if (tooltipRect.x + maxWidthHalf > innerWidth) {
        tooltip.style.left = `-${tooltipRect.x + maxWidthHalf - innerWidth}px`;
      } else if (tooltipRect.x - maxWidthHalf < 0) {
        tooltip.style.left = `${maxWidthHalf - tooltipRect.x}px`;
      }
    });
  };
  useEffect(() => {
    setTimeout(reposition, 300);
  }, []);
};
