import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { colorBlack, colorWhite, hexToRgbA, tabletMax } from '~/common/utils';
import { Link } from '~/common/models';
import {
  IconEnum,
  SvgIcon,
  TooltipDisclaimer,
} from '~/common/components/ui-elements';
import { useRTETooltipPositionCorrector } from '~/common/hooks/use-rteTooltipPositionCorrector';

interface IMosaicTile {
  colSpan: number;
  rowSpan: number;
  contentType?: string;
  imageSrc?: string;
  header?: string;
  headerVerticalAlignment?: string;
  displayHeaderOverMedia?: boolean;
  fontColor?: string;
  backgroundColor?: string;
  url?: Link;
}

interface IMosaic {
  id?: number;
  gridColumnCount?: number;
  tiles?: IMosaicTile[];
  tilesForMobileView?: IMosaicTile[];
  indent?: boolean;
  title?: string;
  titleDisclaimer?: string;
  fontColor?: string;
  backgroundColor?: string;
}

const MosaicTile = React.memo(
  (
    props: IMosaicTile & {
      idx: number;
      isMobile: boolean;
    }
  ) => {
    const { idx, isMobile, ...tile } = props;

    const mapContentAlignment = () => {
      switch (tile.headerVerticalAlignment?.toLowerCase()) {
        case 'top':
          return 'start';
        case 'center':
          return 'center';
        case 'bottom':
        default:
          return 'end';
      }
    };

    const handleIconClick = () => {
      if (!!tile.url?.url) {
        if (tile.url.target === '_self') {
          window.location.href = tile.url.url;
        } else {
          window.open(tile.url?.url, '_blank');
        }
      }
    };

    return (
      <div
        key={idx}
        className={cn(styles.MosaicTile, {
          [styles.MosaicTileHover]: !isMobile,
        })}
        style={{
          gridColumn: `span ${tile.colSpan}`,
          gridRow: `span ${tile.rowSpan}`,
          cursor: !!tile.url?.url ? 'pointer' : 'default',
          aspectRatio: `${
            !isMobile ? `${tile.colSpan}/${tile.rowSpan}` || '1 / 1' : ''
          }`,
        }}
      >
        <div
          className={cn(styles.TileContentContainer)}
          onClick={!isMobile ? handleIconClick : undefined}
        >
          <img src={tile.imageSrc} />
          <div
            className={cn(styles.TileContent, {
              [styles.TileContentMobile]:
                !tile.displayHeaderOverMedia && isMobile,
            })}
            style={{
              ...(tile.backgroundColor
                ? {
                    backgroundColor: `${hexToRgbA(
                      `#${tile.backgroundColor}`,
                      !tile.displayHeaderOverMedia && isMobile ? '1' : '.33'
                    )}`,
                  }
                : {}),
              ...(tile.fontColor ? { color: `#${tile.fontColor}` } : {}),
            }}
          >
            <div
              className={styles.TileHeader}
              style={{
                justifyContent: mapContentAlignment(),
              }}
              dangerouslySetInnerHTML={{
                __html: tile.header as string,
              }}
            />
            {!tile.displayHeaderOverMedia && isMobile && !!tile.url?.url && (
              <div className={styles.TileIconContainer}>
                <SvgIcon
                  className={styles.TileIcon}
                  type={IconEnum.plusCircle}
                  color={`#${tile.fontColor}`}
                  size={1.15}
                  strokeWidth={1}
                  onClick={handleIconClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

export const Mosaic = React.memo(
  ({
    id,
    gridColumnCount,
    tiles,
    indent,
    tilesForMobileView,
    title,
    titleDisclaimer,
    fontColor,
    backgroundColor,
  }: IMosaic) => {
    const _gridColumnCount = gridColumnCount || 12;
    const [mosaicTiles, setMosaicTiles] = useState(tiles);

    const [isMobile, setIsMobile] = useState(
      useMediaQuery(
        { query: `(max-width: ${tabletMax}px)` },
        undefined,

        (matches) => {
          setIsMobile(matches);
        }
      )
    );
    useRTETooltipPositionCorrector(isMobile);

    useEffect(() => {
      setMosaicTiles(!isMobile ? tiles : tilesForMobileView);
    }, [isMobile]);

    return (
      <div
        key={id}
        id={`cid-${id}`}
        className={cn('full-device-width', styles.MosaicWrapper, {
          [styles.MosaicWrapperIndent]: indent && !isMobile,
        })}
        style={{
          backgroundColor: `#${backgroundColor || colorWhite}`,
        }}
      >
        <div
          className={cn(styles.MosaicContainer, {
            [styles.MosaicContainerIndent]: indent && !isMobile,
          })}
        >
          <MediaQuery minWidth={0}>
            {title && (
              <div className={styles.MosaicHeader}>
                <div
                  style={{
                    ...(fontColor
                      ? {
                          color: `#${fontColor}`,
                        }
                      : {}),
                  }}
                  dangerouslySetInnerHTML={{ __html: title as string }}
                />
                {titleDisclaimer && (
                  <TooltipDisclaimer
                    triggerClassName={styles.HeaderDisclaimerTrigger}
                    disclaimer={titleDisclaimer}
                  />
                )}
              </div>
            )}
            <div
              className={styles.Mosaic}
              style={{
                gridTemplateColumns: `repeat(${_gridColumnCount}, 1fr)`,
              }}
            >
              {mosaicTiles?.map((tile, idx) =>
                tile.contentType === 'MosaicTile' ? (
                  <MosaicTile {...{ ...tile, idx, isMobile }} />
                ) : null
              )}
            </div>
          </MediaQuery>
        </div>
      </div>
    );
  }
);
